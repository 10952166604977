@use "global" as *;

.c-page-section-title {
  font-size: rem(10);
}

.c-page-section-title span::before {
  content: "";
  width: rem(16);
  height: 2px;
  background-color: $black;
  display: inline-block;
  margin-right: rem(4);
}

.c-page-section-title::before {
  content: attr(data-en);
  margin-right: rem(8);
  font-size: rem(16);
  line-height: 1.25;
  font-family: $font-en;
  text-transform: uppercase;

  @include mq(md) {
    margin-right: rem(16);
    font-size: rem(24);
  }
}

.c-page-section-title--white span::before {
  background-color: $white;
}