@use "global" as *;

.p-message__inner {
  @include mq(md) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.p-message__body {
  @include mq(md) {
    width: rem(564);
    padding: rem(64) 0 rem(64) rem(120);
  }
}

.p-message__sub-title {
  margin-top: rem(34);
  font-size: rem(24);
  letter-spacing: 0.025em;

  @include mq(md) {
    margin-top: rem(62);
    font-size: rem(40);
  }
}

.p-message_text {
  margin-top: rem(36);
  font-size: rem(12);
  line-height: 2;

  @include mq(md) {
    margin-top: rem(55);
    font-size: rem(14);
  }
}

.p-message__img {
  margin-top: rem(50);
  width: calc(100% + #{rem(34)});
  margin-left: rem(-34);
  height: rem(320);

  @include mq(md) {
    position: relative;
    width: calc(100% - #{rem(564)} + #{rem(110)});
    margin-left: rem(-110);
    margin-top: 0;
    height: rem(720);
  }
}

.p-message__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}