@use "global" as *;

.p-value {
  position: relative;
  color: $white;
}

.p-value__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.p-value__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-value__img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: $black, $alpha: 0.7);
}

.p-value__title {
  text-align: center;
}

.p-value__cards {
  margin-top: rem(48);

  @include mq(md) {
    display: flex;
    margin-top: rem(64);
  }
}

.p-value__card:nth-child(n+2) {
  margin-top: rem(48);
  padding-top: rem(48);
  border-top: 2px solid $white;

  @include mq(md) {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    margin-left: rem(56);
    padding-left: rem(56);
    border-left: 2px solid $white;
  }
}