@use "global" as *;

.p-card-products {
  display: flex;
  flex-direction: column-reverse;

  @include mq(md) {
    flex-direction: row;
    gap: rem(80);
  }
}

.p-card-products:nth-child(even) {
  @include mq(md) {
    flex-direction: row-reverse;
  }
}

.p-card-products__body {
  @include mq(md) {
    width: 50%;
  }
}

.p-card-products__title {
  margin-top: rem(32);
  font-size: rem(24);
  line-height: 1.25;
  letter-spacing: 0.025em;

  @include mq(md) {
    font-size: rem(40);
  }
}

.p-card-products__title span {
  font-size: rem(10);
  font-family: $font-en;
  display: block;

  @include mq(md) {
    margin-top: rem(16);
    font-size: rem(12);
  }
}

.p-card-products__title span::before {
  content: "";
  width: rem(16);
  height: 2px;
  background-color: $black;
  display: inline-block;
  margin-right: rem(4);
}

.p-card-products__sub-title {
  margin-top: rem(32);
  font-size: rem(14);
  font-weight: bold;
  letter-spacing: 0.025em;

  @include mq(md) {
    margin-top: rem(59);
    font-size: rem(20);
  }
}

.p-card-products__text {
  margin-top: rem(14);
  font-size: rem(12);
  line-height: 2;

  @include mq(md) {
    margin-top: rem(28);
    font-size: rem(14);
  }
}

.p-card-products__btn-list {
  margin-top: rem(34);
  display: flex;
  flex-direction: column;
  gap: rem(12);

  @include mq(md) {
    margin-top: rem(65);
    flex-direction: row;
    gap: rem(24);
  }
}

.p-card-products__img {
  width: calc(100% + #{rem(34)});

  @include mq(md) {
    width: calc(50% + #{rem(110)});
    margin-right: rem(-110);
  }
}

.p-card-products:nth-child(even) .p-card-products__img {
  margin-left: rem(-34);

  @include mq(md) {
    margin-right: 0;
    margin-left: rem(-110);
  }
}