@use "global" as *;

.p-link-box {
  @include mq(md) {
    display: flex;
  }
}

.p-link-box__recruit {
  @include mq(md) {
    width: 47.5%;
  }
}

.p-link-box__contact {
  @include mq(md) {
    width: 52.5%;
  }
}