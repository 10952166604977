@use "global" as *;

.c-section-title {
  font-size: rem(10);
  font-weight: bold;
  display: inline-flex;
  align-items: baseline;
  gap: rem(8);
  line-height: 1;

  @include mq(md) {
    font-size: rem(12);
    gap: rem(16);
  }
}

.c-section-title::before {
  content: attr(data-en);
  font-family: $font-en;
  font-size: rem(32);
  text-transform: uppercase;

  @include mq(md) {
    font-size: rem(60);
  }
}

.c-section-title span {
  display: inline-flex;
  align-items: baseline;
  gap: rem(4);
}

.c-section-title span::before {
  content: "";
  width: rem(16);
  height: 2px;
  background-color: $black;
}

.c-section-title--white::before {
  font-size: rem(24);

  @include mq(md) {
    font-size: rem(48);
  }
}

.c-section-title--white span::before {
  background-color: $white;
}