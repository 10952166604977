@use "global" as *;

.p-news__inner {
  position: relative;
}

.p-news__slide {
  margin-top: rem(30);
  position: relative;
}

.p-news__swiper {
  margin-right: calc(50% - 50vw) !important;
}


.p-news__meta {
  display: flex;
  align-items: center;
  margin-top: rem(20);
}

.p-news__time {
  font-family: $font-en;
  font-size: rem(12);
  letter-spacing: 0.025em;
  margin-right: rem(8);
}

.p-news__cate-list {
  display: flex;
  flex-wrap: wrap;
}

.p-news__cate {
  font-size: rem(10);
  color: #aaa;
  padding: 0 rem(6);
  display: block;
  letter-spacing: 0.025em;
  position: relative;
}

.p-news__cate:nth-child(n+2)::before {
  content: "";
  width: 1px;
  height: rem(9);
  background-color: #aaa;
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
}

.p-news__card-text {
  margin-top: rem(12);
  font-size: rem(14);
  font-weight: bold;
  letter-spacing: 0.025em;

  @include mq(md) {
    margin-top: rem(16);
  }
}

.p-news__scrollbar.swiper-scrollbar.swiper-scrollbar-horizontal {
  bottom: rem(-20) !important;

  @include mq(md) {
    bottom: rem(-40) !important;
  }
}

.p-news__btn {
  margin-top: rem(80);
  text-align: right;

  @include mq(md) {
    position: absolute;
    top: 0;
    right: rem(110);
    margin-top: 0;
  }
}