@use "global" as *;

.p-mission__inner {
  @include mq(md) {
    display: flex;
  }
}

.p-mission__body {
  @include mq(md) {
    width: rem(564);
    padding-top: rem(64);
  }
}

.p-mission__sub-title {
  width: calc(100% + #{rem(2)});
  margin: rem(48) 0 rem(-12) rem(-2);
  z-index: 1;
  position: relative;

  @include mq(md) {
    margin-top: rem(72);
    width: rem(640);
  }
}

.p-mission__img {
  width: 100%;
  position: relative;
  right: rem(-34);
  height: rem(240);

  @include mq(md) {
    width: calc(100% - #{rem(564)} + #{rem(110)});
    margin-right: rem(-80);
    height: auto;
  }
}

.p-mission__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-mission__lead {
  margin-top: rem(40);
  font-size: rem(20);
  font-weight: bold;
  letter-spacing: 0.025em;

  @include mq(md) {
    margin-top: rem(80);
    font-size: rem(32);
  }
}

.p-mission__text {
  margin-top: rem(26);
  font-size: rem(12);
  line-height: 2;
  font-weight: bold;
  letter-spacing: 0.025em;

  @include mq(md) {
    font-size: rem(16);
    line-height: 2.5;
    margin-top: rem(28);
  }
}

.p-mission__text:first-of-type {
  margin-top: rem(40);

  @include mq(md) {
    margin-top: rem(64);
  }
}