@use "global" as *;

.p-company__inner {
  @include mq(md) {
    display: flex;
    align-items: stretch;
  }
}

.p-company__body {
  @include mq(md) {
    width: rem(564);
    padding-right: rem(120);
  }
}

.p-company__table {
  margin-top: rem(40);
  display: flex;
  flex-wrap: wrap;

  @include mq(md) {
    margin-top: rem(72);
  }
}

.p-company__table dt,
.p-company__table dd {
  padding: rem(20) 0;
  font-size: rem(12);
  border-top: 2px solid $black;

  @include mq(md) {
    padding: rem(24);
    font-size: rem(14);
  }
}

.p-company__table dt {
  width: 40%;
  font-weight: bold;
}

.p-company__table dd {
  width: 60%;
}

.p-company__table dd span {
  display: block;
  font-size: rem(10);
}

.p-company__table dt:last-of-type,
.p-company__table dd:last-child {
  border-bottom: 2px solid $black;
}

.p-company__map {
  width: calc(100% + #{rem(34)});
  height: rem(240);
  margin-right: rem(-34);
  position: relative;
  margin-top: rem(48);

  @include mq(md) {
    width: calc(100% - #{rem(564)} + #{rem(110)});
    margin-right: rem(-110);
    margin-top: 0;
    height: initial;
  }
}

.p-company__map iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-company__btn {
  position: absolute;
  right: rem(34);
  bottom: rem(-24);

  @include mq(md) {
    right: rem(110);
    bottom: rem(-32);
  }
}