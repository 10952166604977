@use "global" as *;

.l-value {
  margin-top: rem(80);
  padding: rem(48) 0 rem(64);

  @include mq(md) {
    margin-top: rem(144);
    padding: rem(80) 0 rem(88);
  }
}