@use "global" as *;

.c-btn {
  width: rem(184);
  height: rem(48);
  padding: 0 rem(20);
  background-color: $black;
  color: $white;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: rem(12);
  font-weight: bold;

  @include mq(md) {
    width: rem(240);
    height: rem(64);
    font-size: rem(14);
  }
}

.c-btn::after {
  content: "";
  width: rem(20);
  height: rem(20);
  background: url(../images/arrow_right.svg) no-repeat center / contain;

  @include mq(md) {
    width: rem(30);
    height: rem(30);
  }
}

.c-btn--white {
  background-color: $white;
  color: $black;
}

.c-btn--white::after {
  background: url(../images/arrow_right-white.svg) no-repeat center / contain;
}