@use "global" as *;

.p-officer__cards {
  margin-top: rem(40);

  @include mq(md) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(64);
    margin-top: rem(72);
  }
}

.p-officer__card:nth-child(n+2) {
  margin-top: rem(40);

  @include mq(md) {
    margin-top: 0;
  }
}