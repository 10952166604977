@use "global" as *;

.p-about__title {
  @include mq(md) {
    margin: 0 0 rem(120) rem(-6);
  }
}

.p-about__wrap {
  @include mq(md) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.p-about__img {
  position: relative;
  right: rem(-34);
  height: rem(240);
  margin-top: rem(-12);
  z-index: -1;

  @include mq(md) {
    width: calc(100% - rem(468) + rem(110));
    margin: rem(-168) rem(-110) 0 0;
    right: auto;
    height: auto;
  }
}

.p-about__img img {
  aspect-ratio: 295/240;
  width: 100%;
  object-fit: cover;
}

.p-about__body {
  margin-top: rem(40);

  @include mq(md) {
    width: rem(468);
    margin-top: 0;
    flex-shrink: 0;
  }
}

.p-about__lead {
  font-size: rem(20);
  letter-spacing: 0.025em;

  @include mq(md) {
    font-size: rem(32);
  }
}

.p-about__text {
  margin-top: rem(23);
  font-size: rem(12);
  line-height: 2.5;
  letter-spacing: 0.025em;
  font-weight: bold;

  @include mq(md) {
    margin-top: rem(52);
    font-size: rem(16);
  }
}

.p-about__btn {
  margin-top: rem(31);

  @include mq(md) {
    margin-top: rem(68);
  }
}