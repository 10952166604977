@use "global" as *;

.p-footer__inner {
  @include mq(md) {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

.p-footer__info {
  display: flex;

  @include mq(md) {
    display: block;
    width: rem(186);
  }
}

.p-footer__logo {
  width: calc((100% - #{rem(32)})/2);
  margin-right: rem(32);

  @include mq(md) {
    width: 100%;
    margin-right: 0;
  }
}

.p-footer__logo a {
  width: rem(104);
  display: block;

  @include mq(md) {
    width: 100%;
  }
}

.p-footer__logo a svg {
  width: 100%;
  object-fit: cover;
}

.p-footer__logo-text {
  margin-top: rem(12);
  font-size: rem(10);
  font-weight: bold;
  letter-spacing: 0.025em;

  @include mq(md) {
    margin-top: rem(40);
    font-size: rem(14);
  }
}

.p-footer__sns {
  width: calc((100% - #{rem(32)})/2);

  @include mq(md) {
    width: 100%;
  }
}

.p-footer__top {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: rem(184);
  max-width: 100%;
  min-height: rem(48);
  font-size: rem(12);
  padding: 0 rem(20);
  background-color: $black;
  color: $white;
  text-transform: uppercase;
  font-family: $font-en;
  font-weight: bold;
  letter-spacing: 0.025em;

  @include mq(md) {
    position: absolute;
    right: rem(110);
    bottom: 0;
  }
}

.p-footer__top::after {
  content: "";
  width: rem(20);
  height: rem(20);
  background: url(../images/arrow_right.svg) no-repeat center / contain;
  transform: rotate(-90deg);
}

.p-footer__sns-list {
  margin-top: rem(20);
  display: flex;
  align-items: center;
  gap: rem(10);

  @include mq(md) {
    margin-top: rem(40);
    gap: rem(20);
  }
}

.p-footer__sns-item {
  width: rem(19);
  height: rem(19);

  @include mq(md) {
    width: rem(30);
    height: rem(30);
  }
}

.p-footer__lang-list {
  margin-top: rem(20);
  display: flex;
  gap: rem(10);

  @include mq(md) {
    margin-top: rem(40);
    gap: rem(20);
  }
}

.p-footer__lang-item {
  font-size: rem(10);
  font-family: $font-en;
  font-weight: bold;
  letter-spacing: 0.025em;
  padding-bottom: rem(6);
}

.p-footer__lang-item:first-child {
  border-bottom: 2px solid;
}

.p-footer__copy {
  margin-top: rem(24);
  font-size: rem(10);
  letter-spacing: 0.025;
  font-weight: bold;
  font-family: $font-en;

  @include mq(md) {
    margin-top: rem(40);
  }
}

.p-footer__nav {
  margin-top: rem(64);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: rem(32);
  row-gap: rem(36);

  @include mq(md) {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 0;
    column-gap: rem(40);
  }
}

.p-footer__list {
  @include mq(md) {
    width: rem(185);
  }
}

.p-footer__list:last-child {
  @include mq(md) {
    width: rem(134);
  }
}


.p-footer__item {
  padding: rem(9) 0;
  font-size: rem(10);
  font-weight: bold;

  @include mq(md) {
    font-size: rem(12);
  }
}

.p-footer__item--bold {
  font-family: $font-en;
  font-size: rem(12);
  margin-bottom: rem(4);
  padding: rem(7) 0;

  @include mq(md) {
    font-size: rem(16);
    padding: rem(8) 0;
    margin-bottom: rem(12);
  }
}