@use "global" as *;

.p-products__inner {
  @include mq(md) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.p-products__body {
  @include mq(md) {
    width: calc(50% - rem(192));
    min-width: rem(380);
    flex-shrink: 0;
  }
}

.p-products__text {
  margin-top: rem(24);
  font-size: rem(12);
  line-height: 2;
  font-weight: bold;
  letter-spacing: 0.025em;

  @include mq(md) {
    margin-top: rem(64);
    font-size: rem(16);
  }
}

.p-products__btn {
  margin-top: rem(30);

  @include mq(md) {
    margin-top: rem(60);
  }
}

.p-products__slide {
  position: relative;
  margin-top: rem(40);


  @include mq(md) {
    margin-top: 0;
    width: calc(50% + rem(96));
    margin-right: rem(96);
  }
}

.p-products__swiper {
  position: relative;
}

.p-products__pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  right: 0 !important;
  left: auto !important;
  width: rem(50) !important;
}

.p-products__scrollbar.swiper-scrollbar.swiper-scrollbar-horizontal {
  bottom: rem(-20) !important;
}

.p-products__img {
  width: 100%;
}

.p-products__img img {
  width: 100%;
  object-fit: cover;
}

.p-products__img-title {
  font-size: rem(16);
  letter-spacing: 0.025em;
  padding-left: rem(34);
  margin-top: rem(20);

  @include mq(md) {
    padding-left: rem(110);
    font-size: rem(24);
  }
}

.p-products__img-title span {
  display: block;
  font-size: rem(10);
  font-family: $font-en;

  @include mq(md) {
    margin-top: rem(8);
    font-size: rem(12);
  }
}

.p-products__img-title span::before {
  content: "";
  width: rem(16);
  height: 2px;
  background-color: $black;
  display: inline-block;
  margin-right: rem(4);
}