@use "global" as *;

.p-card-value__title {
  font-size: rem(20);
  letter-spacing: 0.025em;

  @include mq(md) {
    font-size: rem(32);
  }
}

.p-card-value__title span {
  display: block;
  margin-top: rem(15);
  font-size: rem(10);
  font-weight: normal;
  font-family: $font-en;

  @include mq(md) {
    margin-top: rem(24);
    font-size: rem(12);
  }
}

.p-card-value__text {
  margin-top: rem(26);
  font-size: rem(12);
  line-height: 2;

  @include mq(md) {
    margin-top: rem(41);
    font-size: rem(14);
  }
}