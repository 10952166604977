@use "global" as *;

.p-card-officer {
  display: flex;

  @include mq(md) {
    display: block;
  }
}

.p-card-officer__name {
  width: rem(96);
  font-size: rem(14);
  letter-spacing: 0.025em;

  @include mq(md) {
    font-size: rem(24);
    width: initial;
  }
}

.p-card-officer__name span {
  display: block;
  margin-top: rem(16);
  font-size: rem(10);

  @include mq(md) {
    margin-top: rem(20);
    font-size: rem(12);
  }
}

.p-card-officer__text {
  width: calc(100% - #{rem(96)});
  font-size: rem(10);
  line-height: 1.75;

  @include mq(md) {
    margin-top: rem(29);
    width: initial;
  }
}