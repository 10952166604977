@use "global" as *;

.c-page-title {
  font-size: rem(10);
  text-align: right;

  @include mq(md) {
    font-size: rem(14);
  }
}

.c-page-title::before {
  content: "";
  width: rem(16);
  height: 2px;
  background-color: $black;
  display: inline-block;
  margin-right: rem(4);
}

.c-page-title::after {
  content: attr(data-en);
  font-size: rem(40);
  display: block;
  text-transform: uppercase;
  font-family: $font-en;
  font-weight: bold;

  @include mq(md) {
    font-size: rem(96);
  }
}