@charset "UTF-8";
html {
  font-size: 100%;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 0.9756097561vw;
  }
}
@media (min-width: 1640px) {
  html {
    font-size: 100%;
  }
}

body {
  font-family: "Noto Sans JP", sans-serif;
  color: #000;
  padding: 0.5rem;
  position: relative;
}

body::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 0.5rem solid #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  a,
  button {
    transition: 0.3s;
  }
  a:hover,
  button:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

/*****************************
* A Modern CSS Reset (https://github.com/hankchizljaw/modern-css-reset)
* 上記に、ul要素,ol要素,a要素への記述追加
*****************************/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
li {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  border: none;
  padding: 0;
  background-color: initial;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.l-about {
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .l-about {
    margin-top: 11.5rem;
  }
}

.l-company {
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .l-company {
    margin-top: 9rem;
  }
}

.l-footer {
  margin-top: 6rem;
  padding-bottom: 5rem;
}
@media screen and (min-width: 768px) {
  .l-footer {
    margin-top: 12.25rem;
    padding-bottom: 10rem;
  }
}

.l-inner {
  width: 100%;
  padding-right: 2.125rem;
  padding-left: 2.125rem;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .l-inner {
    max-width: 1640px;
    padding-right: 6.875rem;
    padding-left: 6.875rem;
  }
}

.l-link-box {
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .l-link-box {
    margin-top: 11.5rem;
  }
}

.l-message {
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .l-message {
    margin-top: 9rem;
  }
}

.l-mission {
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .l-mission {
    margin-top: 6.5rem;
  }
}

.l-news {
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .l-news {
    margin-top: 10rem;
  }
}

.l-officer {
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .l-officer {
    margin-top: 9.5rem;
  }
}

.l-products-block {
  margin-top: 3rem;
}
@media screen and (min-width: 768px) {
  .l-products-block {
    margin-top: 6.5rem;
  }
}

.l-products {
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .l-products {
    margin-top: 9rem;
  }
}

.l-value {
  margin-top: 5rem;
  padding: 3rem 0 4rem;
}
@media screen and (min-width: 768px) {
  .l-value {
    margin-top: 9rem;
    padding: 5rem 0 5.5rem;
  }
}

.c-btn {
  width: 11.5rem;
  height: 3rem;
  padding: 0 1.25rem;
  background-color: #000;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .c-btn {
    width: 15rem;
    height: 4rem;
    font-size: 0.875rem;
  }
}

.c-btn::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  background: url(../images/arrow_right.svg) no-repeat center/contain;
}
@media screen and (min-width: 768px) {
  .c-btn::after {
    width: 1.875rem;
    height: 1.875rem;
  }
}

.c-btn--white {
  background-color: #fff;
  color: #000;
}

.c-btn--white::after {
  background: url(../images/arrow_right-white.svg) no-repeat center/contain;
}

.c-fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.js-fadeUpTrigger {
  opacity: 0;
}

.c-page-section-title {
  font-size: 0.625rem;
}

.c-page-section-title span::before {
  content: "";
  width: 1rem;
  height: 2px;
  background-color: #000;
  display: inline-block;
  margin-right: 0.25rem;
}

.c-page-section-title::before {
  content: attr(data-en);
  margin-right: 0.5rem;
  font-size: 1rem;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .c-page-section-title::before {
    margin-right: 1rem;
    font-size: 1.5rem;
  }
}

.c-page-section-title--white span::before {
  background-color: #fff;
}

.c-page-title {
  font-size: 0.625rem;
  text-align: right;
}
@media screen and (min-width: 768px) {
  .c-page-title {
    font-size: 0.875rem;
  }
}

.c-page-title::before {
  content: "";
  width: 1rem;
  height: 2px;
  background-color: #000;
  display: inline-block;
  margin-right: 0.25rem;
}

.c-page-title::after {
  content: attr(data-en);
  font-size: 2.5rem;
  display: block;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .c-page-title::after {
    font-size: 6rem;
  }
}

.c-section-title {
  font-size: 0.625rem;
  font-weight: bold;
  display: inline-flex;
  align-items: baseline;
  gap: 0.5rem;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .c-section-title {
    font-size: 0.75rem;
    gap: 1rem;
  }
}

.c-section-title::before {
  content: attr(data-en);
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .c-section-title::before {
    font-size: 3.75rem;
  }
}

.c-section-title span {
  display: inline-flex;
  align-items: baseline;
  gap: 0.25rem;
}

.c-section-title span::before {
  content: "";
  width: 1rem;
  height: 2px;
  background-color: #000;
}

.c-section-title--white::before {
  font-size: 1.5rem;
}
@media screen and (min-width: 768px) {
  .c-section-title--white::before {
    font-size: 3rem;
  }
}

.c-section-title--white span::before {
  background-color: #fff;
}

@media screen and (min-width: 768px) {
  .p-about__title {
    margin: 0 0 7.5rem -0.375rem;
  }
}

@media screen and (min-width: 768px) {
  .p-about__wrap {
    display: flex;
    flex-direction: row-reverse;
  }
}

.p-about__img {
  position: relative;
  right: -2.125rem;
  height: 15rem;
  margin-top: -0.75rem;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .p-about__img {
    width: calc(100% - 29.25rem + 6.875rem);
    margin: -10.5rem -6.875rem 0 0;
    right: auto;
    height: auto;
  }
}

.p-about__img img {
  aspect-ratio: 295/240;
  width: 100%;
  object-fit: cover;
}

.p-about__body {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-about__body {
    width: 29.25rem;
    margin-top: 0;
    flex-shrink: 0;
  }
}

.p-about__lead {
  font-size: 1.25rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-about__lead {
    font-size: 2rem;
  }
}

.p-about__text {
  margin-top: 1.4375rem;
  font-size: 0.75rem;
  line-height: 2.5;
  letter-spacing: 0.025em;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .p-about__text {
    margin-top: 3.25rem;
    font-size: 1rem;
  }
}

.p-about__btn {
  margin-top: 1.9375rem;
}
@media screen and (min-width: 768px) {
  .p-about__btn {
    margin-top: 4.25rem;
  }
}

.p-card-officer {
  display: flex;
}
@media screen and (min-width: 768px) {
  .p-card-officer {
    display: block;
  }
}

.p-card-officer__name {
  width: 6rem;
  font-size: 0.875rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-card-officer__name {
    font-size: 1.5rem;
    width: initial;
  }
}

.p-card-officer__name span {
  display: block;
  margin-top: 1rem;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-card-officer__name span {
    margin-top: 1.25rem;
    font-size: 0.75rem;
  }
}

.p-card-officer__text {
  width: calc(100% - 6rem);
  font-size: 0.625rem;
  line-height: 1.75;
}
@media screen and (min-width: 768px) {
  .p-card-officer__text {
    margin-top: 1.8125rem;
    width: initial;
  }
}

.p-card-products {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .p-card-products {
    flex-direction: row;
    gap: 5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-card-products:nth-child(even) {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 768px) {
  .p-card-products__body {
    width: 50%;
  }
}

.p-card-products__title {
  margin-top: 2rem;
  font-size: 1.5rem;
  line-height: 1.25;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-card-products__title {
    font-size: 2.5rem;
  }
}

.p-card-products__title span {
  font-size: 0.625rem;
  font-family: "Montserrat", sans-serif;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-card-products__title span {
    margin-top: 1rem;
    font-size: 0.75rem;
  }
}

.p-card-products__title span::before {
  content: "";
  width: 1rem;
  height: 2px;
  background-color: #000;
  display: inline-block;
  margin-right: 0.25rem;
}

.p-card-products__sub-title {
  margin-top: 2rem;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-card-products__sub-title {
    margin-top: 3.6875rem;
    font-size: 1.25rem;
  }
}

.p-card-products__text {
  margin-top: 0.875rem;
  font-size: 0.75rem;
  line-height: 2;
}
@media screen and (min-width: 768px) {
  .p-card-products__text {
    margin-top: 1.75rem;
    font-size: 0.875rem;
  }
}

.p-card-products__btn-list {
  margin-top: 2.125rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
@media screen and (min-width: 768px) {
  .p-card-products__btn-list {
    margin-top: 4.0625rem;
    flex-direction: row;
    gap: 1.5rem;
  }
}

.p-card-products__img {
  width: calc(100% + 2.125rem);
}
@media screen and (min-width: 768px) {
  .p-card-products__img {
    width: calc(50% + 6.875rem);
    margin-right: -6.875rem;
  }
}

.p-card-products:nth-child(even) .p-card-products__img {
  margin-left: -2.125rem;
}
@media screen and (min-width: 768px) {
  .p-card-products:nth-child(even) .p-card-products__img {
    margin-right: 0;
    margin-left: -6.875rem;
  }
}

.p-card-value__title {
  font-size: 1.25rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-card-value__title {
    font-size: 2rem;
  }
}

.p-card-value__title span {
  display: block;
  margin-top: 0.9375rem;
  font-size: 0.625rem;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .p-card-value__title span {
    margin-top: 1.5rem;
    font-size: 0.75rem;
  }
}

.p-card-value__text {
  margin-top: 1.625rem;
  font-size: 0.75rem;
  line-height: 2;
}
@media screen and (min-width: 768px) {
  .p-card-value__text {
    margin-top: 2.5625rem;
    font-size: 0.875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-company__inner {
    display: flex;
    align-items: stretch;
  }
}

@media screen and (min-width: 768px) {
  .p-company__body {
    width: 35.25rem;
    padding-right: 7.5rem;
  }
}

.p-company__table {
  margin-top: 2.5rem;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .p-company__table {
    margin-top: 4.5rem;
  }
}

.p-company__table dt,
.p-company__table dd {
  padding: 1.25rem 0;
  font-size: 0.75rem;
  border-top: 2px solid #000;
}
@media screen and (min-width: 768px) {
  .p-company__table dt,
  .p-company__table dd {
    padding: 1.5rem;
    font-size: 0.875rem;
  }
}

.p-company__table dt {
  width: 40%;
  font-weight: bold;
}

.p-company__table dd {
  width: 60%;
}

.p-company__table dd span {
  display: block;
  font-size: 0.625rem;
}

.p-company__table dt:last-of-type,
.p-company__table dd:last-child {
  border-bottom: 2px solid #000;
}

.p-company__map {
  width: calc(100% + 2.125rem);
  height: 15rem;
  margin-right: -2.125rem;
  position: relative;
  margin-top: 3rem;
}
@media screen and (min-width: 768px) {
  .p-company__map {
    width: calc(100% - 35.25rem + 6.875rem);
    margin-right: -6.875rem;
    margin-top: 0;
    height: initial;
  }
}

.p-company__map iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-company__btn {
  position: absolute;
  right: 2.125rem;
  bottom: -1.5rem;
}
@media screen and (min-width: 768px) {
  .p-company__btn {
    right: 6.875rem;
    bottom: -2rem;
  }
}

.p-contact {
  color: #fff;
  position: relative;
  border-top: 2px solid #fff;
}
@media screen and (min-width: 768px) {
  .p-contact {
    border-top: none;
    border-left: 2px solid #fff;
  }
}

.p-contact::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.p-contact__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}

.p-contact__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-contact__inner {
  padding-top: 3rem;
  padding-bottom: 3.5rem;
}
@media screen and (min-width: 768px) {
  .p-contact__inner {
    padding-top: 6rem;
    padding-bottom: 6.5rem;
  }
}

.p-contact__text {
  margin-top: 1.875rem;
  font-size: 0.75rem;
  line-height: 2;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-contact__text {
    margin-top: 2rem;
    font-size: 1rem;
  }
}

.p-contact__btn {
  margin-top: 2.125rem;
}
@media screen and (min-width: 768px) {
  .p-contact__btn {
    margin-top: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-footer__inner {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

.p-footer__info {
  display: flex;
}
@media screen and (min-width: 768px) {
  .p-footer__info {
    display: block;
    width: 11.625rem;
  }
}

.p-footer__logo {
  width: calc((100% - 2rem) / 2);
  margin-right: 2rem;
}
@media screen and (min-width: 768px) {
  .p-footer__logo {
    width: 100%;
    margin-right: 0;
  }
}

.p-footer__logo a {
  width: 6.5rem;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-footer__logo a {
    width: 100%;
  }
}

.p-footer__logo a svg {
  width: 100%;
  object-fit: cover;
}

.p-footer__logo-text {
  margin-top: 0.75rem;
  font-size: 0.625rem;
  font-weight: bold;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-footer__logo-text {
    margin-top: 2.5rem;
    font-size: 0.875rem;
  }
}

.p-footer__sns {
  width: calc((100% - 2rem) / 2);
}
@media screen and (min-width: 768px) {
  .p-footer__sns {
    width: 100%;
  }
}

.p-footer__top {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 11.5rem;
  max-width: 100%;
  min-height: 3rem;
  font-size: 0.75rem;
  padding: 0 1.25rem;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-footer__top {
    position: absolute;
    right: 6.875rem;
    bottom: 0;
  }
}

.p-footer__top::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  background: url(../images/arrow_right.svg) no-repeat center/contain;
  transform: rotate(-90deg);
}

.p-footer__sns-list {
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-footer__sns-list {
    margin-top: 2.5rem;
    gap: 1.25rem;
  }
}

.p-footer__sns-item {
  width: 1.1875rem;
  height: 1.1875rem;
}
@media screen and (min-width: 768px) {
  .p-footer__sns-item {
    width: 1.875rem;
    height: 1.875rem;
  }
}

.p-footer__lang-list {
  margin-top: 1.25rem;
  display: flex;
  gap: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-footer__lang-list {
    margin-top: 2.5rem;
    gap: 1.25rem;
  }
}

.p-footer__lang-item {
  font-size: 0.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.025em;
  padding-bottom: 0.375rem;
}

.p-footer__lang-item:first-child {
  border-bottom: 2px solid;
}

.p-footer__copy {
  margin-top: 1.5rem;
  font-size: 0.625rem;
  letter-spacing: 0.025;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .p-footer__copy {
    margin-top: 2.5rem;
  }
}

.p-footer__nav {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2.25rem;
}
@media screen and (min-width: 768px) {
  .p-footer__nav {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 0;
    column-gap: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-footer__list {
    width: 11.5625rem;
  }
}

@media screen and (min-width: 768px) {
  .p-footer__list:last-child {
    width: 8.375rem;
  }
}

.p-footer__item {
  padding: 0.5625rem 0;
  font-size: 0.625rem;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .p-footer__item {
    font-size: 0.75rem;
  }
}

.p-footer__item--bold {
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  padding: 0.4375rem 0;
}
@media screen and (min-width: 768px) {
  .p-footer__item--bold {
    font-size: 1rem;
    padding: 0.5rem 0;
    margin-bottom: 0.75rem;
  }
}

.p-fv__title {
  padding-right: 2.125rem;
  padding-left: 2.125rem;
  margin-bottom: -1rem;
  position: relative;
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .p-fv__title {
    margin-bottom: -2rem;
    padding: 0 5rem;
  }
}

.p-fv__sub-title {
  width: 8.25rem;
  min-height: 0.75rem;
  margin-bottom: 1.5rem;
  margin: 0 0 1.4375rem auto;
}
@media screen and (min-width: 768px) {
  .p-fv__sub-title {
    width: 15rem;
    min-height: 1.375rem;
  }
}

.p-fv__main-title {
  width: calc(100% + 0.25rem);
  position: relative;
  margin-left: auto;
  right: -2px;
}
@media screen and (min-width: 768px) {
  .p-fv__main-title {
    width: 43.25rem;
    right: -0.375rem;
  }
}

.p-fv__slide {
  width: 100%;
  overflow: hidden;
}

.p-fv__list {
  width: 202.5rem;
  display: flex;
  animation: Visual_marqueeImg__oyIfe 50s linear infinite;
}
@media screen and (min-width: 768px) {
  .p-fv__list {
    width: 251.25rem;
  }
}

.p-fv__list2 {
  margin-top: 0.75rem;
  animation-direction: reverse;
}
@media screen and (min-width: 768px) {
  .p-fv__list2 {
    margin-top: 1.25rem;
  }
}

@keyframes Visual_marqueeImg__oyIfe {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.p-fv__item {
  width: 18.75rem;
  height: 10.5625rem;
  margin-right: 0.75rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-fv__item {
    width: 23.125rem;
    height: 13rem;
    margin-right: 1.25rem;
    padding: 0.375rem;
  }
}

.p-fv__item::before {
  content: "";
  width: 100%;
  height: 99.65%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0.375rem solid #000;
}

.p-header {
  display: flex;
  padding: 2.125rem 2.125rem 0;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .p-header {
    padding: 5rem 5rem 0;
    margin-bottom: -1rem;
  }
}

.p-header__logo {
  width: 4.5rem;
  margin-right: 4rem;
  z-index: 500;
}
@media screen and (min-width: 768px) {
  .p-header__logo {
    width: 6.75rem;
  }
}

.p-header__nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: hsla(0, 0%, 100%, 0.5);
  backdrop-filter: blur(40px);
  padding: 9.375rem 2.125rem;
}
@media screen and (min-width: 768px) {
  .p-header__nav {
    flex-grow: 1;
    display: flex;
    gap: 2.5rem;
    margin-top: -1rem;
    position: static;
    background: none;
    backdrop-filter: none;
    padding: 0;
  }
}

.p-header__item {
  padding: 1rem 0;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.p-header__lang-list {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-header__lang-list {
    gap: 1.25rem;
    margin-top: 1.25rem;
    display: flex;
  }
}

.p-header__lang-item {
  font-size: 0.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.025em;
  padding-bottom: 0.375rem;
  position: relative;
}

.p-header__lang-item:first-child a {
  display: inline-block;
  border-bottom: 2px solid;
}

.p-header__humbarger {
  width: 1.875rem;
  height: 1.25rem;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  cursor: pointer;
  z-index: 500;
}
@media screen and (min-width: 768px) {
  .p-header__humbarger {
    display: none;
  }
}

.p-header__humbarger--fixed {
  top: 1.5625rem;
  right: 1.5625rem;
}

.p-header__humbarger span {
  width: 100%;
  height: 2px;
  border-radius: 0.625rem;
  background-color: #000;
  display: block;
  position: absolute;
  left: 0;
  transition: 0.3s;
}

.p-header__humbarger span:nth-child(1) {
  top: 0;
}

.p-header__humbarger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.p-header__humbarger span:nth-child(3) {
  bottom: 0;
}

.p-header__humbarger.show span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.p-header__humbarger.show span:nth-child(2) {
  opacity: 0;
}

.p-header__humbarger.show span:nth-child(3) {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%) rotate(45deg);
}

.p-header__fixed {
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  transform: translateY(-78px);
  z-index: 200;
  background-color: #fff;
  width: calc(100% - 1rem);
  height: 3.875rem;
  padding: 0;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .p-header__fixed {
    top: auto;
    bottom: 0.5rem;
    left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.875rem;
    padding: 0 2rem;
    transform: translateY(78px);
  }
}

.p-header__fixed.transform {
  transform: translateY(0);
}

.p-header__fixed-logo {
  width: 7.75rem;
  position: absolute;
  top: 1.6875rem;
  left: 1.625rem;
}
@media screen and (min-width: 768px) {
  .p-header__fixed-logo {
    width: 10.375rem;
    position: static;
  }
}

.p-header__fixed-nav {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-header__fixed-nav {
    flex-grow: 1;
    display: block;
  }
}

.p-header__fixed-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.p-header__fixed-item {
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  padding: 2rem 1rem;
}

.p-header__fixed-lang-list {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-header__fixed-lang-list {
    margin-left: 1.5rem;
    display: flex;
    gap: 1.25rem;
  }
}

.p-header__fixed-lang-item {
  font-size: 0.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.025em;
  padding-bottom: 0.375rem;
  position: relative;
}

.p-header__fixed-lang-item:first-child a {
  display: inline-block;
  border-bottom: 2px solid;
}

@media screen and (min-width: 768px) {
  .p-link-box {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .p-link-box__recruit {
    width: 47.5%;
  }
}

@media screen and (min-width: 768px) {
  .p-link-box__contact {
    width: 52.5%;
  }
}

@media screen and (min-width: 768px) {
  .p-message__inner {
    display: flex;
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 768px) {
  .p-message__body {
    width: 35.25rem;
    padding: 4rem 0 4rem 7.5rem;
  }
}

.p-message__sub-title {
  margin-top: 2.125rem;
  font-size: 1.5rem;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-message__sub-title {
    margin-top: 3.875rem;
    font-size: 2.5rem;
  }
}

.p-message_text {
  margin-top: 2.25rem;
  font-size: 0.75rem;
  line-height: 2;
}
@media screen and (min-width: 768px) {
  .p-message_text {
    margin-top: 3.4375rem;
    font-size: 0.875rem;
  }
}

.p-message__img {
  margin-top: 3.125rem;
  width: calc(100% + 2.125rem);
  margin-left: -2.125rem;
  height: 20rem;
}
@media screen and (min-width: 768px) {
  .p-message__img {
    position: relative;
    width: calc(100% - 35.25rem + 6.875rem);
    margin-left: -6.875rem;
    margin-top: 0;
    height: 45rem;
  }
}

.p-message__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

@media screen and (min-width: 768px) {
  .p-mission__inner {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .p-mission__body {
    width: 35.25rem;
    padding-top: 4rem;
  }
}

.p-mission__sub-title {
  width: calc(100% + 0.125rem);
  margin: 3rem 0 -0.75rem -0.125rem;
  z-index: 1;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-mission__sub-title {
    margin-top: 4.5rem;
    width: 40rem;
  }
}

.p-mission__img {
  width: 100%;
  position: relative;
  right: -2.125rem;
  height: 15rem;
}
@media screen and (min-width: 768px) {
  .p-mission__img {
    width: calc(100% - 35.25rem + 6.875rem);
    margin-right: -5rem;
    height: auto;
  }
}

.p-mission__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-mission__lead {
  margin-top: 2.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-mission__lead {
    margin-top: 5rem;
    font-size: 2rem;
  }
}

.p-mission__text {
  margin-top: 1.625rem;
  font-size: 0.75rem;
  line-height: 2;
  font-weight: bold;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-mission__text {
    font-size: 1rem;
    line-height: 2.5;
    margin-top: 1.75rem;
  }
}

.p-mission__text:first-of-type {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-mission__text:first-of-type {
    margin-top: 4rem;
  }
}

.p-news__inner {
  position: relative;
}

.p-news__slide {
  margin-top: 1.875rem;
  position: relative;
}

.p-news__swiper {
  margin-right: calc(50% - 50vw) !important;
}

.p-news__meta {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
}

.p-news__time {
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  letter-spacing: 0.025em;
  margin-right: 0.5rem;
}

.p-news__cate-list {
  display: flex;
  flex-wrap: wrap;
}

.p-news__cate {
  font-size: 0.625rem;
  color: #aaa;
  padding: 0 0.375rem;
  display: block;
  letter-spacing: 0.025em;
  position: relative;
}

.p-news__cate:nth-child(n+2)::before {
  content: "";
  width: 1px;
  height: 0.5625rem;
  background-color: #aaa;
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
}

.p-news__card-text {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-news__card-text {
    margin-top: 1rem;
  }
}

.p-news__scrollbar.swiper-scrollbar.swiper-scrollbar-horizontal {
  bottom: -1.25rem !important;
}
@media screen and (min-width: 768px) {
  .p-news__scrollbar.swiper-scrollbar.swiper-scrollbar-horizontal {
    bottom: -2.5rem !important;
  }
}

.p-news__btn {
  margin-top: 5rem;
  text-align: right;
}
@media screen and (min-width: 768px) {
  .p-news__btn {
    position: absolute;
    top: 0;
    right: 6.875rem;
    margin-top: 0;
  }
}

.p-officer__cards {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-officer__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    margin-top: 4.5rem;
  }
}

.p-officer__card:nth-child(n+2) {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-officer__card:nth-child(n+2) {
    margin-top: 0;
  }
}

.p-page-fv__text {
  margin-top: 3.125rem;
  font-size: 0.875rem;
  line-height: 1.75;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .p-page-fv__text {
    margin-top: 7.5rem;
    line-height: 2;
    font-size: 1.125rem;
  }
}

.p-page-fv__link-list {
  margin-top: 3.125rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: 1.5rem;
}
@media screen and (min-width: 768px) {
  .p-page-fv__link-list {
    margin-top: 4.375rem;
    justify-content: flex-start;
  }
}

.p-page-fv__item {
  width: calc((100% - 1.5rem) / 2);
  padding: 0.6875rem 0;
  font-size: 0.6875rem;
  text-align: right;
  font-weight: bold;
  letter-spacing: 0.025em;
  color: #aaa;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
@media screen and (min-width: 768px) {
  .p-page-fv__item {
    width: auto;
    font-size: 0.875rem;
  }
}

.p-page-fv__item::after {
  content: "↓";
}

.p-recruit {
  color: #fff;
  position: relative;
}

.p-recruit::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.p-recruit__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}

.p-recruit__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-recruit__inner {
  padding-top: 3rem;
  padding-bottom: 3.5rem;
}
@media screen and (min-width: 768px) {
  .p-recruit__inner {
    padding-top: 6rem;
    padding-bottom: 6.5rem;
  }
}

.p-recruit__text {
  margin-top: 1.875rem;
  font-size: 0.75rem;
  line-height: 2;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-recruit__text {
    margin-top: 2rem;
    font-size: 1rem;
  }
}

.p-recruit__btn {
  margin-top: 2.125rem;
}
@media screen and (min-width: 768px) {
  .p-recruit__btn {
    margin-top: 2.5rem;
  }
}

.p-products-block__card:nth-child(n+2) {
  margin-top: 5rem;
}

@media screen and (min-width: 768px) {
  .p-products__inner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .p-products__body {
    width: calc(50% - 12rem);
    min-width: 23.75rem;
    flex-shrink: 0;
  }
}

.p-products__text {
  margin-top: 1.5rem;
  font-size: 0.75rem;
  line-height: 2;
  font-weight: bold;
  letter-spacing: 0.025em;
}
@media screen and (min-width: 768px) {
  .p-products__text {
    margin-top: 4rem;
    font-size: 1rem;
  }
}

.p-products__btn {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-products__btn {
    margin-top: 3.75rem;
  }
}

.p-products__slide {
  position: relative;
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-products__slide {
    margin-top: 0;
    width: calc(50% + 6rem);
    margin-right: 6rem;
  }
}

.p-products__swiper {
  position: relative;
}

.p-products__pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  right: 0 !important;
  left: auto !important;
  width: 3.125rem !important;
}

.p-products__scrollbar.swiper-scrollbar.swiper-scrollbar-horizontal {
  bottom: -1.25rem !important;
}

.p-products__img {
  width: 100%;
}

.p-products__img img {
  width: 100%;
  object-fit: cover;
}

.p-products__img-title {
  font-size: 1rem;
  letter-spacing: 0.025em;
  padding-left: 2.125rem;
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-products__img-title {
    padding-left: 6.875rem;
    font-size: 1.5rem;
  }
}

.p-products__img-title span {
  display: block;
  font-size: 0.625rem;
  font-family: "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .p-products__img-title span {
    margin-top: 0.5rem;
    font-size: 0.75rem;
  }
}

.p-products__img-title span::before {
  content: "";
  width: 1rem;
  height: 2px;
  background-color: #000;
  display: inline-block;
  margin-right: 0.25rem;
}

.p-value {
  position: relative;
  color: #fff;
}

.p-value__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.p-value__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-value__img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.p-value__title {
  text-align: center;
}

.p-value__cards {
  margin-top: 3rem;
}
@media screen and (min-width: 768px) {
  .p-value__cards {
    display: flex;
    margin-top: 4rem;
  }
}

.p-value__card:nth-child(n+2) {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 2px solid #fff;
}
@media screen and (min-width: 768px) {
  .p-value__card:nth-child(n+2) {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    margin-left: 3.5rem;
    padding-left: 3.5rem;
    border-left: 2px solid #fff;
  }
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none;
  }
}