@use "global" as *;

.p-header {
  display: flex;
  padding: rem(34) rem(34) 0;
  margin-bottom: rem(32);

  @include mq(md) {
    padding: rem(80) rem(80) 0;
    margin-bottom: rem(-16);
  }
}

.p-header__logo {
  width: rem(72);
  margin-right: rem(64);
  z-index: 500;

  @include mq(md) {
    width: rem(108);
  }
}

.p-header__nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: hsla(0, 0%, 100%, .5);
  backdrop-filter: blur(40px);
  padding: rem(150) rem(34);

  @include mq(md) {
    flex-grow: 1;
    display: flex;
    gap: rem(40);
    margin-top: rem(-16);
    position: static;
    background: none;
    backdrop-filter: none;
    padding: 0;
  }
}

.p-header__item {
  padding: rem(16) 0;
  font-size: rem(14);
  font-weight: bold;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-family: $font-en;
}

.p-header__lang-list {

  display: none;

  @include mq(md) {
    gap: rem(20);
    margin-top: rem(20);
    display: flex;
  }
}

.p-header__lang-item {
  font-size: rem(10);
  font-family: $font-en;
  font-weight: bold;
  letter-spacing: 0.025em;
  padding-bottom: rem(6);
  position: relative;
}

.p-header__lang-item:first-child a {
  display: inline-block;
  border-bottom: 2px solid;
}

.p-header__humbarger {
  width: rem(30);
  height: rem(20);
  position: absolute;
  top: rem(40);
  right: rem(40);
  cursor: pointer;
  z-index: 500;

  @include mq(md) {
    display: none;
  }
}

.p-header__humbarger--fixed {
  top: rem(25);
  right: rem(25);
}

.p-header__humbarger span {
  width: 100%;
  height: 2px;
  border-radius: rem(10);
  background-color: $black;
  display: block;
  position: absolute;
  left: 0;
  transition: .3s;
}

.p-header__humbarger span:nth-child(1) {
  top: 0;
}

.p-header__humbarger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.p-header__humbarger span:nth-child(3) {
  bottom: 0;
}

.p-header__humbarger.show span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.p-header__humbarger.show span:nth-child(2) {
  opacity: 0;
}

.p-header__humbarger.show span:nth-child(3) {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%) rotate(45deg);
}

.p-header__fixed {
  position: fixed;
  top: rem(8);
  left: rem(8);
  transform: translateY(-78px);
  z-index: 200;
  background-color: $white;
  width: calc(100% - #{rem(16)});
  height: rem(62);
  padding: 0;
  transition: .3s;

  @include mq(md) {
    top: auto;
    bottom: rem(8);
    left: rem(8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(78);
    padding: 0 rem(32);
    transform: translateY(78px);
  }

}

.p-header__fixed.transform {
  transform: translateY(0);
}

.p-header__fixed-logo {
  width: rem(124);
  position: absolute;
  top: rem(27);
  left: rem(26);

  @include mq(md) {
    width: rem(166);
    position: static;
  }
}

.p-header__fixed-nav {
  display: none;

  @include mq(md) {
    flex-grow: 1;
    display: block;
  }
}

.p-header__fixed-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.p-header__fixed-item {
  font-weight: bold;
  font-family: $font-en;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-size: rem(14);
  padding: rem(32) rem(16);
}

.p-header__fixed-lang-list {
  display: none;

  @include mq(md) {
    margin-left: rem(24);
    display: flex;
    gap: rem(20);
  }
}

.p-header__fixed-lang-item {
  font-size: rem(10);
  font-family: $font-en;
  font-weight: bold;
  letter-spacing: 0.025em;
  padding-bottom: rem(6);
  position: relative;
}

.p-header__fixed-lang-item:first-child a {
  display: inline-block;
  border-bottom: 2px solid;
}