@use "global" as *;

.p-page-fv__text {
  margin-top: rem(50);
  font-size: rem(14);
  line-height: 1.75;
  font-weight: bold;

  @include mq(md) {
    margin-top: rem(120);
    line-height: 2;
    font-size: rem(18);
  }
}

.p-page-fv__link-list {
  margin-top: rem(50);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: rem(24);

  @include mq(md) {
    margin-top: rem(70);
    justify-content: flex-start;
  }
}

.p-page-fv__item {
  width: calc((100% - #{rem(24)})/2);
  padding: rem(11) 0;
  font-size: rem(11);
  text-align: right;
  font-weight: bold;
  letter-spacing: 0.025em;
  color: #aaa;
  display: flex;
  justify-content: flex-end;
  gap: rem(8);

  @include mq(md) {
    width: auto;
    font-size: rem(14);
  }
}

.p-page-fv__item::after {
  content: "↓";
}