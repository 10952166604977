@use "global" as *;

.p-contact {
  color: $white;
  position: relative;
  border-top: 2px solid $white;

  @include mq(md) {
    border-top: none;
    border-left: 2px solid $white;
  }
}

.p-contact::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba($color: $black, $alpha: 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.p-contact__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}

.p-contact__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-contact__inner {
  padding-top: rem(48);
  padding-bottom: rem(56);

  @include mq(md) {
    padding-top: rem(96);
    padding-bottom: rem(104);
  }
}

.p-contact__text {
  margin-top: rem(30);
  font-size: rem(12);
  line-height: 2;
  letter-spacing: 0.025em;

  @include mq(md) {
    margin-top: rem(32);
    font-size: rem(16);

  }
}

.p-contact__btn {
  margin-top: rem(34);

  @include mq(md) {
    margin-top: rem(40);
  }
}