@use "global" as *;

.p-fv__title {
  padding-right: rem(34);
  padding-left: rem(34);
  margin-bottom: rem(-16);
  position: relative;
  z-index: 10;

  @include mq(md) {
    margin-bottom: rem(-32);
    padding: 0 rem(80);
  }
}

.p-fv__sub-title {
  width: rem(132);
  min-height: rem(12);
  margin-bottom: rem(24);
  margin: 0 0 rem(23) auto;

  @include mq(md) {
    width: rem(240);
    min-height: rem(22);
  }
}

.p-fv__main-title {
  width: calc(100% + #{rem(4)});
  position: relative;
  margin-left: auto;
  right: -2px;

  @include mq(md) {
    width: rem(692);
    right: rem(-6);
  }
}

.p-fv__slide {
  width: 100%;
  overflow: hidden;
}

.p-fv__list {
  width: rem(3240);
  display: flex;
  animation: Visual_marqueeImg__oyIfe 50s linear infinite;

  @include mq(md) {
    width: rem(4020);
  }
}

.p-fv__list2 {
  margin-top: rem(12);
  animation-direction: reverse;

  @include mq(md) {
    margin-top: rem(20);
  }
}

@keyframes Visual_marqueeImg__oyIfe {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.p-fv__item {
  width: rem(300);
  height: rem(169);
  margin-right: rem(12);
  position: relative;

  @include mq(md) {
    width: rem(370);
    height: rem(208);
    margin-right: rem(20);
    padding: rem(6);
  }
}

.p-fv__item::before {
  content: "";
  width: 100%;
  height: 99.65%;
  position: absolute;
  top: 0;
  left: 0;
  border: rem(6) solid $black;
}