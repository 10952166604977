@use "global" as *;

.l-footer {
  margin-top: rem(96);
  padding-bottom: rem(80);

  @include mq(md) {
    margin-top: rem(196);
    padding-bottom: rem(160);
  }
}